import React from 'react'
import Footer from '../../layout/Footer'
import GiftCardIcon from '../../assets/gift-card-icon.png'

export const GiftCardErrorPage = () => {
  return (
    <>
      <div className={'flex flex-col w-full h-screen justify-center'}>
        <div>
          <div className={'md:mx-10 flex justify-center overflow-hidden'}>
            <img
              src={GiftCardIcon}
              alt='giftCardErrorIcon'
              className={'giftcard-error'}
            />
          </div>
        </div>
        <div className={'mx-auto'}>
          <h1 className={'type-large text-center font-normal m-6'}>
            Sorry! Purchasing E-Gift Cards is not available for this restaurant.
          </h1>
        </div>
      </div>
      <div className={'fixed bottom-0 w-full flex justify-center'}>
        <Footer />
      </div>
    </>
  )
}
