import React, { ReactNode } from 'react'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { isInternationalCountryIsoCode } from '@toasttab/buffet-pui-country-utilities'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { MOCK_RESTAURANT } from '@local/mocks'
import { RESTAURANT_BY_URL } from '../../../src/apollo/queries/RestaurantByShortUrl'
import {
  isGiftCardConfigDisabled,
  isGiftCardFeatureFlagEnabled
} from './functions'
import { Restaurant, RestaurantResponse } from './types'
import tracker from '@local/tracking/src/tracker'

export const RestaurantContext =
  React.createContext<Restaurant>(MOCK_RESTAURANT)

export const useRestaurantContext = () => {
  return React.useContext(RestaurantContext)
}

interface RestaurantContextProviderProps {
  shortUrl: string | undefined
  children: ReactNode
}

export const RestaurantContextProvider = ({
  shortUrl,
  children
}: RestaurantContextProviderProps) => {
  const { enabledFeatureFlags } = window.EGC
  const navigator = useNavigate()

  const featureEnabled = isGiftCardFeatureFlagEnabled(enabledFeatureFlags)

  const { loading, error, data } = useQuery<RestaurantResponse>(
    RESTAURANT_BY_URL,
    {
      skip: !featureEnabled,
      variables: {
        shortUrl: shortUrl
      }
    }
  )

  if (!featureEnabled) {
    tracker.track('FeatureFlag not enabled', { shortUrl: shortUrl })
    navigator('/unavailable')
    return <></>
  }

  if (loading) {
    return <MerryGoRound className='pin-center' size='md' />
  }

  if (error || !data) {
    tracker.track('GraphQL error', { shortUrl: shortUrl })
    navigator('/unavailable')
    return <></>
  }

  const restaurant = data.restaurantV2ByShortUrl
  const isIntl = isInternationalCountryIsoCode(restaurant?.i18n?.country)
  if (!isIntl) {
    tracker.track('Old shop navigation', { shortUrl: shortUrl })
    navigator('/old-egiftcard-shop')
    return <></>
  }

  if (isGiftCardConfigDisabled(restaurant)) {
    tracker.track('GiftCard module disabled', { shortUrl: shortUrl })
    navigator('/unavailable')
    return <></>
  }

  return (
    <RestaurantContext.Provider value={restaurant}>
      {children}
    </RestaurantContext.Provider>
  )
}
