import React, { createContext, useMemo } from 'react'
import { useRestaurantContext } from '@local/restaurant'
import { useGetGiftCardConfig } from '../hooks/useGetGiftCardConfig'
import { useNavigate, useParams } from 'react-router-dom'
import { Currency } from '@toasttab/buffet-pui-country-utilities'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { EGiftCardConfig } from '../app/types'
import { minorToMajorUnits } from '../Utils/configUtils'
import tracker from '@local/tracking/src/tracker'

const DEFAULT_EGIFT_CARD_CONFIG: EGiftCardConfig = {
  eGiftCardPurchasingEnabled: false,
  minimumPurchaseAmount: {
    currency: Currency.CAD,
    value: 0.01
  },
  maximumPurchaseAmount: {
    currency: Currency.CAD,
    value: 150.0
  },
  eGiftCardImage: ''
}

const GiftCardConfigContext = createContext<EGiftCardConfig>(
  DEFAULT_EGIFT_CARD_CONFIG
)

export const useGiftCardConfigContext = () => {
  return React.useContext(GiftCardConfigContext)
}

export const GiftCardConfigContextProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const navigator = useNavigate()
  const params = useParams()
  const { guid } = useRestaurantContext()

  const { isGiftCardConfigLoading, isGiftCardConfigError, giftCardConfig } =
    useGetGiftCardConfig(guid, true)

  // will be undefined if eGiftCardPurchasing not enabled OR no config data
  const eGiftCardConfigInMajorUnits: EGiftCardConfig | undefined =
    useMemo(() => {
      if (giftCardConfig?.data?.eGiftCardConfig.eGiftCardPurchasingEnabled) {
        const {
          eGiftCardConfig: {
            minimumPurchaseAmount,
            maximumPurchaseAmount,
            eGiftCardPurchasingEnabled,
            eGiftCardImage
          }
        } = giftCardConfig?.data

        return {
          eGiftCardPurchasingEnabled,
          eGiftCardImage,
          minimumPurchaseAmount: {
            ...minimumPurchaseAmount,
            value: minorToMajorUnits(minimumPurchaseAmount.value)
          },
          maximumPurchaseAmount: {
            ...maximumPurchaseAmount,
            value: minorToMajorUnits(maximumPurchaseAmount.value)
          }
        }
      }
    }, [giftCardConfig])

  if (isGiftCardConfigLoading) {
    return <MerryGoRound className='pin-center' size='md' />
  }

  if (isGiftCardConfigError || !eGiftCardConfigInMajorUnits) {
    tracker.track('EGiftCard config not enabled', { shortUrl: params.shortUrl })
    navigator('/unavailable')
    return <></>
  }

  return (
    <GiftCardConfigContext.Provider value={eGiftCardConfigInMajorUnits}>
      {children}
    </GiftCardConfigContext.Provider>
  )
}
