import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache
} from '@apollo/client'

export const createClient = (uri: string) => {
  const httpLink = new HttpLink({
    uri: uri,
    headers: {
      // Required for all services using consumer-app-bff or do-federated-gateway
      'Apollographql-Client-Name': 'giftcard-public-spa',
      'Toast-Session-ID': typeof window !== 'undefined' ? window.__SESSION__?.id : null
    }
  })

  const authLink = new ApolloLink((operation, forward) => {
    return forward(operation)
  })

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  })
}
