const PROD_PREFIX = 'https://ws-api.toasttab.com'

export const PROD_CONFIG = {
  toastWebBaseUrl: 'https://www.toasttab.com/',
  consumerBffUrl: `${PROD_PREFIX}/do-federated-gateway/v1/graphql`,
  giftcardProcessingUrl: `${PROD_PREFIX}/giftcard-order-processing/v1/egiftcards/orders`,
  giftCardConfigUrl: `${PROD_PREFIX}/giftcard-order-processing/v1/egiftcards/config/egiftcards`,
  recaptchaKey: '6LeXZp0pAAAAAO3UaxaMXbnYXp3v7hCjx5Lki2yo',
  amplitudeApiKey: '0176e802d1b68c3cb8294a5ac3def894'
}
