import { Currency } from '@toasttab/buffet-pui-number-utilities'
import { GiftCardInfo } from '../../../src/app/types'
import { Restaurant, RestaurantResponse } from '../../restaurant'

export const MOCK_RESTAURANT: Restaurant = {
  guid: 'test-guid',
  shortUrl: '',
  name: 'test name',
  description: '',
  i18n: {
    currency: Currency.EUR,
    locale: 'en-IE',
    country: 'IE'
  },
  logoUrls: {
    medium: '',
    large: ''
  },
  giftCardConfig: {
    hasGiftCardsExtension: false,
    purchaseAllowed: true
  }
}

export const MOCK_GRAPHQL_RESPONSE: RestaurantResponse = {
  restaurantV2ByShortUrl: MOCK_RESTAURANT
}

export const MOCK_GIFTCARDINFO: GiftCardInfo = {
  amount: 0,
  senderEmail: '',
  to: '',
  from: '',
  receiverEmail: '',
  deliveryDateTime: undefined,
  message: 'test gc message'
}
