import { GiftCard, Sender } from '../app/types'
import { GiftCardContextType } from '../context/GiftCardContext'

export const MOCK_GIFTCARD: GiftCard = {
  recipient: {
    email: '',
    to: ''
  },
  amount: undefined,
  sendType: 'EMAIL',
  message: '',
  deliveryDateTime: undefined
}

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
export const MOCK_GIFTCARD_CONTEXT: GiftCardContextType = {
  recaptchaToken: '',
  paymentGuid: '',
  sender: {
    email: '',
    phone: '',
    from: ''
  },
  giftCards: undefined,
  paymentAmount: 0,
  isSuccess: false,
  isLoading: true,
  isError: false,
  setRecaptcha: (values: string) => {},
  setPaymentGuid: (values: string) => {},
  setSender: (values: Sender) => {},
  addGiftCard: (values: GiftCard) => {},
  setPaymentAmount: (values: number) => {},
  onPaymentCompleted: () => {}
}
