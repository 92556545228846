export enum DeliveryTimeSelection {
  NOW = 'NOW',
  FUTURE = 'FUTURE'
}

export type FormValues = {
  amount: number | undefined
  recipientName: string
  senderName: string
  message: string
  recipientEmail: string
  senderEmail: string
  deliveryTimeSelection: DeliveryTimeSelection
  deliveryDate: Date | undefined
  formComplete: boolean
}

export interface GiftCardFormProps {
  setFormValues: (values: FormValues) => void
}
