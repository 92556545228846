export const isValidTextInput = (input: string | undefined): boolean => {
  if (!input) {
    return true
  }
  const invalidCharacters = new RegExp('^[^<>%$={}]*$')
  return invalidCharacters.test(input)
}

export const isValidDateInput = (input: Date | undefined): boolean => {
  return !input ? false : input >= new Date()
}
