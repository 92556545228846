import React from 'react'
import { useParams } from 'react-router-dom'
import { GiftCard } from '../GiftCard/GiftCard'
import Container from '../../layout/Container'
import Footer from '../../layout/Footer'
import { GiftCardPreview } from '../GiftCardPreview/GiftCardPreview'
import { RestaurantContextProvider } from '../../../packages/restaurant'
import { GiftCardContextProvider } from '../../context/GiftCardContext'
import { GiftCardConfigContextProvider } from '../../context/GiftCardConfigContextProvider'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import tracker from '@local/tracking/src/tracker'

export const GiftCardPage = () => {
  const params = useParams()
  return (
    <RestaurantContextProvider shortUrl={params.shortUrl}>
      <GiftCardConfigContextProvider>
        <GiftCardContextProvider>
          <Container>
            <GiftCardPageContent shortUrl={params.shortUrl} />
          </Container>
        </GiftCardContextProvider>
      </GiftCardConfigContextProvider>
    </RestaurantContextProvider>
  )
}

const GiftCardPageContent = (shortUrl: any) => {
  const size = useScreenSize()
  tracker.track('EGiftCard page loaded', { shortUrl: shortUrl })
  if (size >= ScreenSize.MD) {
    return <GiftCardPageContentDesktop />
  } else {
    return <GiftCardPageContentMobile />
  }
}

const GiftCardPageContentDesktop = () => {
  return (
    <>
      <div className='flex flex-col basis-1/2 px-2'>
        <div className='basis-full content-center'>
          <GiftCardPreview />
        </div>
        <div className='basis-1/10 justify-end'>
          <Footer />
        </div>
      </div>
      <div className='basis-1/2 bg-white shadow grid content-center'>
        <GiftCard />
      </div>
    </>
  )
}

const GiftCardPageContentMobile = () => {
  return (
    <div className='flex flex-col w-full gap-6'>
      <div className={'flex m-3 justify-center'}>
        <GiftCardPreview />
      </div>
      <div className='justify-center px-4 py-6 bg-white rounded-lg'>
        <GiftCard />
      </div>
      <div className='basic-full content-center justify-end'>
        <Footer />
      </div>
    </div>
  )
}
