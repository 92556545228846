import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { Image } from '@toasttab/buffet-pui-image'
import React from 'react'
import { DEFAULT_GIFTCARD_IMAGE_URL } from '../../config'
import cx from 'classnames'

export const GiftCardImage = ({ imageUrl }: { imageUrl: string }) => {
  const isSmallScreen = useScreenSize() <= ScreenSize.SM
  return (
    <div
      className={cx(
        'row-span-2 mt-3',
        isSmallScreen ? 'img-container-sm' : 'img-container-md'
      )}
    >
      <Image
        src={imageUrl}
        alt='giftCardImage'
        fit='cover'
        showError={true}
        errorComp={
          <div className='flex items-center justify-center w-full h-full'>
            <Image
              src={DEFAULT_GIFTCARD_IMAGE_URL}
              alt={'giftCardImage'}
            ></Image>
          </div>
        }
      />
    </div>
  )
}
