import { G2_EGIFTCARD_FF } from './constants'
import { Restaurant } from './types'

export const isGiftCardConfigDisabled = (restaurant: Restaurant) => {
  return (
    restaurant.giftCardConfig.hasGiftCardsExtension ||
    !restaurant.giftCardConfig.purchaseAllowed
  )
}

export const isGiftCardFeatureFlagEnabled = (featureFlags: string[]) => {
  return (
    featureFlags?.filter((flag: string) => flag === G2_EGIFTCARD_FF).length > 0
  )
}
