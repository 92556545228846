const LOCAL_PREFIX = 'https://ws-preprod-api.eng.toasttab.com'

export const LOCAL_CONFIG = {
  toastWebBaseUrl: 'https://dev.eng.toastteam.com/',
  consumerBffUrl: `${LOCAL_PREFIX}/do-federated-gateway/v1/graphql`,
  giftcardProcessingUrl: `${LOCAL_PREFIX}/giftcard-order-processing/v1/egiftcards/orders`,
  giftCardConfigUrl: `${LOCAL_PREFIX}/giftcard-order-processing/v1/egiftcards/config/egiftcards`,
  recaptchaKey: '6LfyC3kpAAAAAM93w8htytwYEMwG8CaG9lUHGEt-',
  amplitudeApiKey: 'fabb3f63615483ceecd25751d57456b1'
}
