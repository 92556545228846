import { useMutation } from 'react-query'
import { GiftCard, GiftCardRequest, Sender } from '../app/types'
import { Currency } from '@toasttab/buffet-pui-types'
import { submitGiftCardOrder } from '../api/api'
import { majorToMinorUnits } from '../Utils/configUtils'

export interface MutationProps {
  recaptchaToken: string
  paymentGuid: string
  paymentAmount: number
  giftCards: GiftCard[]
  sender: Sender
  restaurantGuid: string
  currency: Currency
}

export const usePostGiftCardOrder = () => {
  return useMutation(
    ({
      recaptchaToken,
      giftCards,
      sender,
      paymentAmount,
      paymentGuid,
      restaurantGuid,
      currency
    }: MutationProps) => {
      const request: GiftCardRequest = buildGiftCardOrderRequest({
        recaptchaToken,
        giftCards,
        sender,
        paymentAmount,
        paymentGuid,
        restaurantGuid,
        currency
      })
      return submitGiftCardOrder(request, restaurantGuid)
    },
    {
      retry: false
    }
  )
}

const buildGiftCardOrderRequest = ({
  recaptchaToken,
  giftCards,
  sender,
  paymentAmount,
  paymentGuid,
  currency
}: MutationProps): GiftCardRequest => {
  return {
    recaptchaToken: recaptchaToken,
    orderDetails: {
      paymentGuid: paymentGuid,
      sender,
      giftCards,
      totalAmount: {
        value: majorToMinorUnits(paymentAmount),
        currency: currency
      }
    }
  }
}
