import React, { useState } from 'react'
import { GiftCardForm, defaultValues } from '../GiftCardForm/GiftCardForm'
import { PaymentForm } from '../../../packages/payment-form'

export const GiftCard = () => {
  const [formValues, setFormValues] = useState(defaultValues)

  const { amount, senderEmail } = formValues

  return (
    <>
      {!formValues.formComplete && (
        <div className='flex justify-center'>
          <GiftCardForm setFormValues={setFormValues} />
        </div>
      )}
      {formValues.formComplete && (
        <div className='flex justify-center'>
          <PaymentForm amount={amount} tipAmount={0} email={senderEmail} />
        </div>
      )}
    </>
  )
}
