import React from 'react'
import { Image } from '@toasttab/buffet-pui-image'
import { formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import Union from '../../assets/union.svg'
import { useRestaurantContext } from '../../../packages/restaurant'
import { useGiftCardContext } from '../../context/GiftCardContext'
import { minorToMajorUnits } from '../../Utils/configUtils'
import { MOCK_GIFTCARD } from '../../__mocks__/GiftCardCard'
import './index.css'
import { useGiftCardConfigContext } from '../../context/GiftCardConfigContextProvider'
import { DEFAULT_GIFTCARD_IMAGE_URL } from '../../config'
import { GiftCardImage } from '../GiftCardImage/GiftCardImage'

type GifCardBalanceProps = {
  balance: number | undefined
}

export const GiftCardPreview = () => {
  const giftCardContext = useRestaurantContext()
  const { giftCards } = useGiftCardContext()
  const currentGiftCard =
    giftCards !== undefined && giftCards.length > 0
      ? giftCards[giftCards.length - 1]
      : MOCK_GIFTCARD
  const { recipient, message, amount } = currentGiftCard
  const giftCardValue = amount ? minorToMajorUnits(amount.value) : 0
  const restaurantName = giftCardContext.name
  const restaurantLogo = giftCardContext.logoUrls?.medium
    ? giftCardContext.logoUrls.medium
    : 'https://placekitten.com/128/128'
  const receiver = recipient.to === '' ? recipient.email : recipient.to
  const { eGiftCardImage } = useGiftCardConfigContext()
  const imageUrl =
    eGiftCardImage === undefined || eGiftCardImage === ''
      ? DEFAULT_GIFTCARD_IMAGE_URL
      : eGiftCardImage

  return (
    <div className='flex flex-col'>
      <div className='text-center justify-center type-default'>
        <div className='type-large text-gray-100 font-medium pb-2 tracking-wide'>
          Give a gift from
        </div>
        <div className='type-headline-3 font-medium pb-12'>
          {restaurantName}
        </div>
        <div className='bg-white w-[340px] md:w-[391px] h-auto shadow overflow-hidden rounded-[20px] type-default mx-auto'>
          <div className='flex w-full relative'>
            <div className={'py-6 flex w-full justify-center'}>
              <Union />
            </div>
          </div>
          <div className='grid grid-rows-3 grid-flow-col gap-4 mx-6 h-[162px] w-[288px] md:h-[193px] md:w-[343px] rounded-[20px] shadow bg-white overflow-hidden'>
            <div className='row-span-1 ... mt-3 px-4 flex'>
              <div className='mr-3 w-1/7 justify-items-start'>
                <Image
                  src={restaurantLogo}
                  alt='restaurant logo'
                  containerClassName='h-[48px] w-[48px] rounded-full'
                  fit='cover'
                  showError={true}
                />
              </div>
              <div className='type-default text-left w-3/5 mr-3'>
                <div className={'type-default font-semibold'}>
                  {restaurantName}
                </div>
              </div>
              <div className='flex w-1/5 justify-end'>
                <GiftCardBalance balance={giftCardValue} />
              </div>
            </div>
            <GiftCardImage imageUrl={imageUrl}></GiftCardImage>
          </div>
          <div className='m-6'>
            <div className='flex justify-center font-normal text-default whitespace-nowrap'>
              <span className='font-medium text-default mr-2'>{'For'}</span>
              {receiver === '' && (
                <span
                  style={{
                    display: 'table-cell',
                    borderBottom: '1px solid',
                    width: '137px',
                    borderColor: 'rgba(0, 0, 0, 0.08)'
                  }}
                >
                  {receiver}
                </span>
              )}
              <span
                className={'type-default font-semibold'}
                style={{
                  borderBottom: '1px solid',
                  borderColor: 'rgba(0, 0, 0, 0.08)'
                }}
              >
                {receiver}
              </span>
            </div>
            <div className='mt-2 mb-2 text-gray-100  text-wrap break-words overflow-hidden'>
              {message}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const GiftCardBalance = ({ balance = 0.0 }: GifCardBalanceProps) => {
  const { i18n } = useRestaurantContext()
  const { currency, locale } = i18n
  return (
    <>
      <div className='mt-2'>
        <div className='type-headline-6 text-right justify-end items-center'>
          {formatCurrency({ amount: balance, currency: currency }, locale)}
        </div>
      </div>
    </>
  )
}
