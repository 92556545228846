import * as amplitude from '@amplitude/analytics-browser'
import { getEnvConfig } from '../../../src/config'

export class Tracker {
  constructor() {
    if (typeof window !== 'undefined') {
      amplitude.init(getEnvConfig().amplitudeApiKey, undefined, {
        disableCookies: true
      })
    }
  }

  /**
   * Tracking function for pushing a tracking event into the tracking queue. Tracking events are composed of
   * an event name, and optional data component. Events are pushed into queue that is synced to the remote server
   * with a set frequency. It also checks for the presence of third party tracking tools and calls those with the
   * provided data as well.
   *
   * Note: Currently the internal tracking queue is disabled, so only 3rd party tracking events are called.
   *
   * @param {string} name - The name of the event.
   * @param {object} [data] - Optional data object to be submitted with the event.
   * @param {boolean} skipAmplitude - Optional flag to skip tracking via Amplitude.
   */
  track(name, data, skipAmplitude = false) {
    if (typeof window !== 'undefined' && !skipAmplitude) {
      amplitude.track(name, data)
    }
  }
}

// Singleton instance used for tracking events on the client
export const tracker = new Tracker()

export default tracker
