import React from 'react'
import PoweredByToast from '../assets/powered-by-toast.svg'

const Footer = () => {
  return (
    <footer className='text-center footer'>
      <PoweredByToast />
      <div>
        <div className='type-caption mb-1' style={{ color: '#666666' }}>
          <a href='https://pos.toasttab.com/privacy'>Privacy Statement</a> |{' '}
          <a
            href='https://pos.toasttab.com/terms-of-service'
            target='_blank'
            rel='noreferrer'
          >
            Terms of Service
          </a>
        </div>
        <div className='type-caption mb-1' style={{ color: '#666666' }}>
          © Toast, Inc. {new Date().getFullYear()} All Rights Reserved
        </div>
      </div>
      <div className='footer-banner'></div>
    </footer>
  )
}

export default Footer
