const SANDBOX_PREFIX = 'https://ws-sandbox-api.eng.toasttab.com'

export const SANDBOX_CONFIG = {
  toastWebBaseUrl: 'https://sandbox.eng.toasttab.com/',
  consumerBffUrl: `${SANDBOX_PREFIX}/do-federated-gateway/v1/graphql`,
  giftcardProcessingUrl: `${SANDBOX_PREFIX}/giftcard-order-processing/v1/egiftcards/orders`,
  giftCardConfigUrl: `${SANDBOX_PREFIX}/giftcard-order-processing/v1/egiftcards/config/egiftcards`,
  recaptchaKey: '6LfyC3kpAAAAAM93w8htytwYEMwG8CaG9lUHGEt-',
  amplitudeApiKey: 'fabb3f63615483ceecd25751d57456b1'
}
