import { useQuery } from 'react-query'
import { getGiftCardConfig } from '../api/api'
import tracker from '@local/tracking/src/tracker'

export const useGetGiftCardConfig = (guid: string, enabled: boolean) => {
  const { isLoading, isError, isSuccess, data } = useQuery(
    [],
    () => getGiftCardConfig(guid),
    {
      enabled: enabled,
      cacheTime: 0,
      staleTime: Infinity,
      retry: true,
      onError: (error: any) => {
        tracker.track('EGiftCard config error', {
          restaurantGuid: guid,
          error: error.message
        })
      }
    }
  )

  return {
    isGiftCardConfigLoading: isLoading,
    isGiftCardConfigError: isError,
    isGiftCardConfigSuccess: isSuccess,
    giftCardConfig: data
  }
}
