import { CheckCircleSelectedFillIcon } from '@toasttab/buffet-pui-icons'
import { Formats, format } from '@toasttab/buffet-pui-date-utilities'
import { useRestaurantContext } from '@local/restaurant'
import { useGiftCardContext } from '../../context/GiftCardContext'
import * as React from 'react'

export const OrderPlaced = () => {
  const { giftCards } = useGiftCardContext()
  const { locale } = useRestaurantContext().i18n
  return (
    <div className='text-center type-default'>
      <div>
        <CheckCircleSelectedFillIcon
          size='2xl'
          className='text-success'
          aria-label='Complete'
        />
      </div>
      {giftCards?.[0].deliveryDateTime ? (
        <div>
          <div
            className='type-large font-medium mt-3 mb-2 text-gray-125'
            data-testid='confirmGiftCardScheduled'
          >
            Gift card successfully scheduled
          </div>
          <div className='text-gray-75'>
            Your gift card will be delivered on{' '}
            <b data-testid='confirmedDeliveryDate'>
              {format(giftCards?.[0].deliveryDateTime, Formats.date.long, {
                locale
              })}
            </b>
          </div>
        </div>
      ) : (
        <div
          className='type-large font-medium mt-3 mb-2 text-gray-125'
          data-testid='confirmGiftCardSent'
        >
          Gift card successfully sent
        </div>
      )}
    </div>
  )
}
