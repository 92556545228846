import axios, { AxiosPromise } from 'axios'
import { getEnvConfig } from '../config'
import {
  GiftCardConfigResponse,
  GiftCardOrderResponse,
  GiftCardRequest
} from '../app/types'

export const submitGiftCardOrder = (
  request: GiftCardRequest,
  restaurantGuid: string
): AxiosPromise<GiftCardOrderResponse> => {
  const { giftcardProcessingUrl } = getEnvConfig()
  return axios.post(giftcardProcessingUrl, request, {
    headers: {
      'Content-Type': 'application/json',
      'Toast-Restaurant-External-ID': restaurantGuid
    }
  })
}

export const getGiftCardConfig = (
  restaurantGuid: string
): AxiosPromise<GiftCardConfigResponse> => {
  const { giftCardConfigUrl } = getEnvConfig()
  return axios.get(giftCardConfigUrl, {
    headers: {
      'Content-Type': 'application/json',
      'Toast-Restaurant-External-ID': restaurantGuid
    }
  })
}
