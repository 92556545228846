import { CancelIcon } from '@toasttab/buffet-pui-icons'
import * as React from 'react'

export const OrderError = () => {
  return (
    <>
      <div className='text-center type-default'>
        <div>
          <CancelIcon size='2xl' className='text-error' aria-label='Complete' />
        </div>
        <div className='type-large font-medium text-gray-125 mt-3'>
          Error in placing order.
        </div>
      </div>
    </>
  )
}
