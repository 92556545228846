import * as React from 'react'
import { AdyenPayment } from '@toasttab/do-secundo-adyen-payment'
import './AdyenPayment.css'
import { useIsIntlRestaurant } from '../../../../src/hooks/useIsIntlRestaurant'
import { useRestaurantContext } from '../../../restaurant'
import { useGiftCardContext } from '../../../../src/context/GiftCardContext'
import { useEffect, useState } from 'react'
import { OrderPlaced } from '../../../../src/components/OrderPlaced/OrderPlaced'
import { useReCaptcha } from '../../../../src/hooks/useRecaptcha'
import { CancelIcon } from '@toasttab/buffet-pui-icons'
import { OrderError } from '../../../../src/components/OrderError/OrderError'
import tracker from '@local/tracking/src/tracker'
import { useParams } from 'react-router-dom'
import { useSentry } from 'banquet-runtime-modules'

export interface PaymentFormProps {
  testId?: string | number
  amount: number | undefined
  tipAmount: number
  email: string
}

const channel = 'EGIFTCARD'

export const PaymentForm = ({
  email,
  amount = 0,
  tipAmount
}: PaymentFormProps) => {
  const {
    paymentGuid,
    paymentAmount,
    setPaymentAmount,
    setPaymentGuid,
    onPaymentCompleted,
    isSuccess,
    isError
  } = useGiftCardContext()
  const isIntlRestaurant = useIsIntlRestaurant()
  const { guid, i18n } = useRestaurantContext()
  const { isRecaptchaLoaded } = useReCaptcha()
  const params = useParams()
  const { currency, locale, country } = i18n
  const [isInitialRender, setIsInitialRender] = useState(true)
  const { hub } = useSentry()

  function paymentCompleted(paymentIntent: string) {
    setPaymentGuid(paymentIntent)
    setPaymentAmount(amount)
    setIsInitialRender(false)
    tracker.track('Payment completed', {
      paymentGuid: paymentIntent,
      amount: amount,
      shortUrl: params.shortUrl
    })
  }

  useEffect(() => {
    if (!isInitialRender) {
      onPaymentCompleted()
    }
  }, [paymentGuid, paymentAmount, isInitialRender])

  if (isSuccess) {
    return <OrderPlaced />
  }

  if (isError) {
    return <OrderError />
  }

  if (!isRecaptchaLoaded) {
    return (
      <>
        <div className='text-center type-default'>
          <div>
            <CancelIcon
              size='2xl'
              className='text-error'
              aria-label='Complete'
            />
          </div>
          <p className='type-large font-medium text-gray-125'>
            Something went wrong!
          </p>
        </div>
      </>
    )
  }

  if (isIntlRestaurant) {
    tracker.track('Payment Screen loaded', { shortUrl: params.shortUrl })
    return (
      <div className='flex flex-col w-5/6 max-w-xs md:w-2/3 md:max-w-lg m-5'>
        <div>
          <AdyenPayment
            payment={{
              checkAmount: amount || 0,
              tipAmount: tipAmount || 0,
              emailAddress: email
            }}
            shouldBlockRedirect={(data) => {
              tracker.track('adyen_should_block_redirect', {
                url: data?.url,
                method: data?.method
              })
              return true
            }}
            onActionHandled={(data) => {
              tracker.track('adyen_action_handled', {
                componentType: data?.componentType,
                actionDescription: data?.actionDescription
              })
            }}
            config={{
              currency,
              locale,
              country,
              guid,
              channel
            }}
            onPaymentCompleted={paymentCompleted}
            sentry={hub}
          />
        </div>
        <div className={'type-caption text-gray-100 pt-2'}>
          I understand my information will be used to process this payment and
          for other purposes outlined in Toast’s{' '}
          <a href='https://pos.toasttab.com/privacy'>Privacy Statement</a>, and
          will also be disclosed to the merchant.
        </div>
      </div>
    )
  } else return <></>
}
