import * as React from 'react'
import { ApolloProvider } from '@apollo/client'
import { GiftCardPage } from '../components/GiftCardPage/GiftCardPage'
import { ToastWebShop } from '../components/ToastWebShop/ToastWebShop'
import { Globals } from './types'
import { BanquetProps } from 'banquet-runtime-modules'
import { getEnvConfig } from '../config'
import { createClient } from '../apollo/apolloUtils'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { GiftCardErrorPage } from '../components/ErrorPage/GiftcardErrorPage'
import { QueryClientProvider } from '../context/QueryClientProvider'

export interface AppProps extends BanquetProps {
  globals: Globals
}

export function App() {
  const envConfig = getEnvConfig()

  return (
    <div data-testid='app-giftcard-public-spa'>
      <ApolloProvider client={createClient(envConfig.consumerBffUrl)}>
        <QueryClientProvider>
          <BrowserRouter basename='/'>
            <Routes>
              <Route path='/egiftcards/:shortUrl' element={<GiftCardPage />} />
              <Route path='/unavailable' element={<GiftCardErrorPage />} />
              <Route path='/old-egiftcard-shop' element={<ToastWebShop />} />
              <Route path='/*' element={<GiftCardErrorPage />} />
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </ApolloProvider>
    </div>
  )
}
