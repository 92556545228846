import React, { ReactNode } from 'react'

type ContainerProps = {
  children: ReactNode
}
const Container = ({ children }: ContainerProps) => {
  return <div className='flex flex-wrap h-screen w-full'>{children}</div>
}

export default Container
