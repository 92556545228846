import { gql } from '@apollo/client'

export const RESTAURANT_BY_URL = gql`
  query RestaurantV2ByShortUrl($shortUrl: String!) {
    restaurantV2ByShortUrl(shortUrl: $shortUrl) {
      ... on Restaurant {
        name
        description
        guid
        i18n {
          currency
          locale
          country
        }
        logoUrls {
          medium
          large
        }
        giftCardConfig {
          hasGiftCardsExtension
          purchaseAllowed
        }
      }
    }
  }
`
