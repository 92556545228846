import React, { createContext, useState } from 'react'
import { GiftCard, Sender } from '../app/types'
import { MOCK_GIFTCARD_CONTEXT } from '../__mocks__/GiftCardCard'
import { usePostGiftCardOrder } from '../hooks/usePostGiftCardOrder'
import { useRestaurantContext } from '@local/restaurant'
import { minorToMajorUnits } from '../Utils/configUtils'
import { useReCaptcha } from '../hooks/useRecaptcha'
import tracker from '@local/tracking/src/tracker'
import { useParams } from 'react-router-dom'

export interface GiftCardContextType {
  recaptchaToken: string | undefined
  paymentGuid: string | undefined
  paymentAmount: number | undefined
  sender: Sender | undefined
  giftCards: GiftCard[] | undefined
  setRecaptcha: (values: string) => void
  setPaymentGuid: (values: string) => void
  setSender: (values: Sender) => void
  addGiftCard: (values: GiftCard) => void
  setPaymentAmount: (values: number) => void
  onPaymentCompleted: () => void
  isSuccess: boolean
  isLoading: boolean
  isError: boolean
}

const GiftCardContext = createContext(MOCK_GIFTCARD_CONTEXT)

export const useGiftCardContext = () => {
  return React.useContext(GiftCardContext)
}

const GiftCardContextProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const [recaptchaToken, setRecaptcha] = useState<string>('')
  const [paymentGuid, setPaymentGuid] = useState<string>('')
  const [sender, setSender] = useState<Sender>({
    email: '',
    from: '',
    phone: ''
  })
  const [giftCards, setGiftCards] = useState<GiftCard[]>([])
  const [paymentAmount, setPaymentAmount] = useState<number>(0)
  const { guid, i18n } = useRestaurantContext()
  const params = useParams()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  const { mutate: placeOrder, isLoading: isOrderLoading } =
    usePostGiftCardOrder()

  const { getRecaptcha, isRecaptchaLoaded: isReCaptchaLoaded } = useReCaptcha()

  const onPaymentCompleted = async () => {
    setIsLoading(isOrderLoading)
    if (!isReCaptchaLoaded) {
      setIsError(true)
      return
    }
    getRecaptcha.mutate('EGiftcard_PlaceOrder', {
      onSuccess: (recaptchaToken: string) => {
        setRecaptcha(recaptchaToken)
        placeOrder(
          {
            recaptchaToken,
            giftCards,
            sender,
            paymentAmount,
            paymentGuid,
            restaurantGuid: guid,
            currency: i18n.currency
          },
          {
            onSuccess: (response) => {
              setIsSuccess(true)
              tracker.track('EGiftCard Order Placed', {
                shortUrl: params.shortUrl,
                orderGuid: response.data.orderGuid,
                paymentGuid: response.data.paymentGuid,
                orderValue: minorToMajorUnits(paymentAmount),
                currency: i18n.currency
              })
            },
            onError: (error: any) => {
              setIsError(true)
              tracker.track('EGiftCard Order Error', {
                shortUrl: params.shortUrl,
                error: error.message
              })
            }
          }
        )
      },
      onError: (error: any) => {
        setIsError(true)
        tracker.track('Recaptcha token Error', {
          shortUrl: params.shortUrl,
          error: error.message
        })
      }
    })
  }

  const addGiftCard = (newGiftCard: GiftCard) => {
    //For mvp just allow one egiftcard only
    const newGiftCardArray = [newGiftCard]
    setGiftCards(newGiftCardArray)
  }

  const value = {
    recaptchaToken,
    paymentGuid,
    sender,
    giftCards,
    paymentAmount,
    isLoading,
    isError,
    isSuccess,
    setRecaptcha,
    setPaymentGuid,
    setSender,
    addGiftCard,
    setPaymentAmount,
    onPaymentCompleted
  }

  return (
    <GiftCardContext.Provider value={value}>
      {children}
    </GiftCardContext.Provider>
  )
}

export { GiftCardContext, GiftCardContextProvider }
