import { LOCAL_CONFIG } from './config-local'
import { PREPROD_CONFIG } from './config-preprod'
import { PROD_CONFIG } from './config-prod'
import { SANDBOX_CONFIG } from './config-sandbox'
import {
  getCurrentEnvironment,
  ToastEnvironment
} from '@toasttab/current-environment'

export const getEnvConfig = () => {
  const currentEnv = getCurrentEnvironment()
  let envConfig
  if (currentEnv === ToastEnvironment.PROD) {
    envConfig = PROD_CONFIG
  } else if (currentEnv === ToastEnvironment.SANDBOX) {
    envConfig = SANDBOX_CONFIG
  } else if (currentEnv === ToastEnvironment.PREPROD) {
    envConfig = PREPROD_CONFIG
  } else {
    envConfig = LOCAL_CONFIG
  }
  return envConfig
}

export * from './constants'
