import { DeliveryTimeSelection } from './types'

export const getDeliveryDateTime = (
  deliveryTimeSelection: DeliveryTimeSelection,
  deliveryDate: Date | undefined
): Date | undefined => {
  // undefined deliveryDateTime will default to deliver now
  if (deliveryTimeSelection === DeliveryTimeSelection.NOW || !deliveryDate) {
    return undefined
  }
  const date = deliveryDate
  date.setHours(8) // default to 8am - will allow time selection in future
  return date
}
