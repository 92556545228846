import { useEffect } from 'react'
import * as React from 'react'
import { getEnvConfig } from '../../config'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

export const ToastWebShop = () => {
  const envConfig = getEnvConfig()
  const url = `${envConfig.toastWebBaseUrl}${window.EGC.shortUrl}/giftcards`
  useEffect(() => {
    window.location.href = url
  }, [url])
  return <MerryGoRound className='pin-center' size='md' />
}
