const PREPROD_PREFIX = 'https://ws-preprod-api.eng.toasttab.com'

export const PREPROD_CONFIG = {
  toastWebBaseUrl: 'https://preprod.eng.toasttab.com/',
  consumerBffUrl: `${PREPROD_PREFIX}/do-federated-gateway/v1/graphql`,
  giftcardProcessingUrl: `${PREPROD_PREFIX}/giftcard-order-processing/v1/egiftcards/orders`,
  giftCardConfigUrl: `${PREPROD_PREFIX}/giftcard-order-processing/v1/egiftcards/config/egiftcards`,
  recaptchaKey: '6LfyC3kpAAAAAM93w8htytwYEMwG8CaG9lUHGEt-',
  amplitudeApiKey: 'fabb3f63615483ceecd25751d57456b1'
}
