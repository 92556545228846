import React from 'react'
import { useParams } from 'react-router-dom'
import { Formik } from 'formik'
import { number, object, string, date } from 'yup'
import { ChevronRightIcon } from '@toasttab/buffet-pui-icons'
import { startOfTomorrow } from '@toasttab/buffet-pui-date-utilities'
import {
  CurrencyField,
  SubmitButton,
  TextareaField,
  TextInputField,
  DatePickerInputField,
  RadioGroupField
} from '@toasttab/buffet-pui-forms'
import { formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import { isValidEmail } from '@toasttab/email-validation'
import { useRestaurantContext } from '@local/restaurant'
import tracker from '@local/tracking/src/tracker'
import { EGiftCardConfig } from '../../app/types'
import { useGiftCardConfigContext } from '../../context/GiftCardConfigContextProvider'
import { useGiftCardContext } from '../../context/GiftCardContext'
import { majorToMinorUnits } from '../../Utils/configUtils'
import { isValidDateInput, isValidTextInput } from '../../Utils/validationUtils'
import { getDeliveryDateTime } from './helpers'
import './styles.css'
import { DeliveryTimeSelection, FormValues, GiftCardFormProps } from './types'

export const defaultValues: FormValues = {
  amount: undefined,
  recipientName: '',
  senderName: '',
  message: '',
  recipientEmail: '',
  senderEmail: '',
  deliveryTimeSelection: DeliveryTimeSelection.NOW,
  deliveryDate: undefined,
  formComplete: false
}

export const GiftCardForm = ({ setFormValues }: GiftCardFormProps) => {
  const params = useParams()
  const { i18n } = useRestaurantContext()
  const { currency, locale } = i18n
  const { setSender, addGiftCard } = useGiftCardContext()
  const { minimumPurchaseAmount, maximumPurchaseAmount }: EGiftCardConfig =
    useGiftCardConfigContext()

  const deliveryDateValidationSchema = date()
    .required('Delivery date is required')
    .test('isValidDate', 'Delivery date must be in the future', (value) =>
      isValidDateInput(value)
    )

  const validationSchema = object({
    amount: number()
      .required('Amount is required')
      .positive()
      .test(
        'isValidAmount',
        'Giftcard maximum exceeded',
        (value) => !value || value <= maximumPurchaseAmount.value
      )
      .test(
        'isValidAmount',
        `Giftcard value must be greater than ${formatCurrency(
          { amount: minimumPurchaseAmount.value, currency },
          locale
        )}`,
        (value) => !value || value >= minimumPurchaseAmount.value
      ),
    recipientName: string().test(
      'isValidName',
      'These special characters cannot be used: {}<>$%=',
      (value) => isValidTextInput(value)
    ),
    senderName: string().test(
      'isValidName',
      'These special characters cannot be used: {}<>$%=',
      (value) => isValidTextInput(value)
    ),
    message: string().test(
      'isValidMessage',
      'These special characters cannot be used: {}<>$%=',
      (value) => isValidTextInput(value)
    ),
    recipientEmail: string()
      .required(`Recipient's email is required`)
      .test(
        'isValidRecipientEmail',
        'Invalid recipient email',
        (value) => !value || isValidEmail(value)
      ),
    senderEmail: string()
      .required(`Sender's email is required`)
      .test(
        'isValidSenderEmail',
        'Invalid sender email',
        (value) => !value || isValidEmail(value)
      ),
    deliveryDate: object({}).when('deliveryTimeSelection', {
      is: DeliveryTimeSelection.FUTURE,
      then: () => deliveryDateValidationSchema
    })
  })

  function submitForm(values: FormValues) {
    values.formComplete = true
    setFormValues(values)
    tracker.track('EGiftCard Info submitted', {
      shortUrl: params.shortUrl,
      amount: values.amount
    })
    const giftCard = {
      recipient: {
        email: values.recipientEmail,
        to: values.recipientName
      },
      amount: {
        value: values.amount ? majorToMinorUnits(values.amount) : 0,
        currency: currency
      },
      sendType: 'EMAIL',
      message: values.message,
      deliveryDateTime: getDeliveryDateTime(
        values.deliveryTimeSelection,
        values.deliveryDate
      )
    }
    setSender({
      from: values.senderName,
      email: values.senderEmail,
      phone: ''
    })
    addGiftCard(giftCard)
  }

  return (
    <div className={'w-9/10 max-w-[340px] md:w-2/3 md:max-w-lg'}>
      <Formik
        onSubmit={async (values) => {
          submitForm(values)
        }}
        validationSchema={validationSchema}
        initialValues={defaultValues}
      >
        {(formik) => {
          return (
            <>
              <form
                id='giftcard-form'
                data-testid='giftcard-form'
                onSubmit={() => {
                  formik.handleSubmit()
                }}
                onChange={() => {
                  setFormValues(formik.values)
                }}
                className='space-y-1'
              >
                <CurrencyField
                  testId={'giftAmount'}
                  required={true}
                  name='amount'
                  currency={currency}
                  label='Gift amount'
                  placeholder={`${formatCurrency(
                    {
                      amount: minimumPurchaseAmount.value,
                      currency
                    },
                    locale
                  )} - ${formatCurrency(
                    {
                      amount: maximumPurchaseAmount.value,
                      currency
                    },
                    locale
                  )}`}
                  preserveHelpSpace={true}
                  onKeyUp={() => {
                    setFormValues(formik.values)
                  }}
                />
                <div className='flex flex-col md:flex-row md:gap-4'>
                  <TextInputField
                    testId={'recipientName'}
                    containerClassName='flex-grow'
                    name='recipientName'
                    label='Who is this gift for?'
                    placeholder='Full name'
                    preserveHelpSpace={true}
                    maxLength={125}
                  />
                  <TextInputField
                    testId={'recipientEmail'}
                    required={true}
                    containerClassName='flex-grow'
                    name='recipientEmail'
                    label='Recipient email'
                    placeholder='Email'
                    preserveHelpSpace={true}
                    maxLength={125}
                  />
                </div>
                <div className='flex flex-col md:flex-row md:gap-4'>
                  <TextInputField
                    testId={'senderName'}
                    containerClassName='flex-grow'
                    name='senderName'
                    label='Who is this gift from?'
                    placeholder='Full name'
                    preserveHelpSpace={true}
                    maxLength={125}
                  />
                  <TextInputField
                    testId={'senderEmail'}
                    required={true}
                    containerClassName='flex-grow'
                    name='senderEmail'
                    label='Sender email'
                    placeholder='Email'
                    preserveHelpSpace={true}
                    maxLength={125}
                  />
                </div>
                <RadioGroupField
                  testId={'deliveryTimeSelection'}
                  name='deliveryTimeSelection'
                  required
                  label='Delivery date'
                  options={[
                    {
                      value: DeliveryTimeSelection.NOW,
                      label: 'Send now'
                    },
                    {
                      value: DeliveryTimeSelection.FUTURE,
                      label: 'Send on a scheduled date'
                    }
                  ]}
                  className='consumer-style-radio-group'
                />
                {formik.values.deliveryTimeSelection ===
                  DeliveryTimeSelection.FUTURE && (
                  <DatePickerInputField
                    testId={'deliveryDatePicker'}
                    name='deliveryDate'
                    label='Scheduled date'
                    required
                    locale={locale}
                    preserveHelpSpace={true}
                    disabledDays={{ before: startOfTomorrow() }}
                  />
                )}
                <TextareaField
                  testId={'message'}
                  name='message'
                  label='Add a custom message (125 characters max)'
                  placeholder='Custom message'
                  preserveHelpSpace={true}
                  containerClassName='flex-grow'
                  maxLength={125}
                />
                <div className='text-center'>
                  <SubmitButton
                    testId='submitButton'
                    form='giftcard-form'
                    size='lg'
                    className='w-full'
                    disabled={!formik.isValid}
                  >
                    Continue to payment
                    <ChevronRightIcon
                      size='sm'
                      className='ml-2'
                      aria-label='Continue to payment'
                    />
                  </SubmitButton>
                </div>
                <div className={'type-caption text-gray-100 pt-2'}>
                  Your email will be used to provide you with digital receipts
                  for your purchases. The information you provide will be
                  disclosed to the merchant and also processed pursuant to
                  Toast’s{' '}
                  <a
                    href='https://pos.toasttab.com/terms-of-service'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    href='https://pos.toasttab.com/privacy'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Privacy Statement
                  </a>
                  .
                </div>
              </form>
            </>
          )
        }}
      </Formik>
    </div>
  )
}
